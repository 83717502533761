<template>
  <div id="app">
    <header>
      <a href="/">
        <img src="./assets/weebt-logo.webp" alt="Logo" class="logo">
      </a>
      <div class="header-social-buttons">
        <a href="https://apps.apple.com/us/app/wee-bt/id6469634932" target="_blank">
          <img src="./assets/small-appstore.webp" alt="Instagram" class="appstore">
        </a>
        <a href="https://play.google.com/store/apps/details?id=io.kodular.leonardobombonato.WeeBT" target="_blank">
          <img src="./assets/small-playstore.webp" alt="Instagram" class="playstore">
        </a>
        <a href="https://www.instagram.com/wee.beachtennis/" target="_blank">
          <img src="./assets/instagram.webp" alt="Instagram" class="instagram">
        </a>
      </div>

    </header>
    <router-view></router-view>
    <footer>
      <div class="footer-container">
        <a href="/politica" class="footer-link">Política de privacidade</a>
        <p>&copy; 2024 WeeBT</p>
      </div>
    </footer>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style land="scss">

body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

header {
  padding: 15px 25px;
  height: 40px;
}

.logo {
  height: 37px;
  float: left;
}

.header-social-buttons {
  float: right;
  display: flex;
  gap: 15px;
}

.header-social-buttons img {
  height: 40px;
}

.footer-link {
  color: #683498;
  text-decoration: none;
}

.footer-container {
  border-top: 1px solid darkgray;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-around;
}
</style>
