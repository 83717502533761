import { createApp } from 'vue'
import App from './App.vue'
import mixpanel from 'mixpanel-browser';
import { createRouter, createWebHistory } from 'vue-router'
import HomePage from './views/HomePage.vue'

mixpanel.init('cad62e2ed36376fa595d935ed48c1fb8', { debug: true, track_pageview: true, persistence: 'localStorage', ignore_dnt: true })

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/politica',
            name: 'PolicyPage',
            component: () => import('./views/PolicyPage.vue')
        },
        {
            path: '/',
            name: 'HomePage',
            component: HomePage
        },
        {
            path: '/app',
            name: 'AppRedirectPage',
            component: () => import('./views/AppRedirectPage.vue')
        }
    ]
});

createApp(App).use(router).mount('#app')


